<template>
	<div class="pageContainer app-container studentContainerTJ flexCloumn">
		<div class="flexCloumn" style="width:100%;height: 74px;">
			<div class="flexBetweenUp" style="width:100%;height: 50%;">
				<div class="flexStartUp" style="width: 40%;height: 100%;">
					<dv-decoration-10 style="width:65%;height:0.1rem;" />
					<dv-decoration-8 :color="['#409eff', '#8cc5ff']" style="width:30%;height:100%;" />
				</div>
				<div class="flexCenterBottom" style="width: 20%;height: 100%;">
					<span style="font-size: 1.5rem;white-space: nowrap;">就业数据统计表</span>
				</div>
				<div class="flexEndUp" style="width: 40%;height: 100%;">
					<dv-decoration-8 :reverse="true" :color="['#409eff', '#8cc5ff']" style="width:30%;height:100%;" />
					<dv-decoration-10 style="width:65%;height:0.1rem; transform: rotateY(180deg);" />
				</div>
			</div>

			<div class="flexBetweenUp" style="width:100%;height: 50%;">
				<div class="flexBetweenUp" style="width: 40%;height: 100%;">
					<div class="react-right ml-4 flexStart" style="margin-left: 3.2%;width: 60%">
						<span class="react-before"></span>
						<span class="text flex_1">
							<div class="flexCenter" style="word-break:keep-all;">
								<span class="barckd" style="word-break:keep-all;"><i
										class="el-icon-date"></i>{{ year }}届毕业生</span>

							</div>
						</span>
					</div>
					<div class="react-right ml-3 flexStart" style="width: 35%">
						<span class="text colorBlue">
							<!-- <div style="color:rgb(92, 217, 232);margin-left:10px;font-size:20px;">11
                            </div> -->

						</span>
					</div>
				</div>
				<div class="flexCenter" style="width: 20%;height: 100%;">
					<dv-decoration-6 class="title-bototm" :reverse="true" :color="['#50e3c2', '#67a1e5']"
						style="width:60%;height:20%; overflow: hidden" />
				</div>
				<div class="flexBetweenUp" style="width: 40%;height: 100%;">
					<div class="react-left bg-color-blue mr-3 flexEnd" style="width: 35%">
						<span class="text fw-b"></span>
					</div>
					<div class="react-left mr-4 flexEnd" style="margin-right: 3.2%;width: 60%">
						<span class="react-after"></span>
						<span class="text">{{ curTime }}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="flexStartUp" style="width: 100%;height: 150px;margin-top:20px;padding-left:4px;">
			<div class="flexCloumn">
				<dv-decoration-9 :dur="20" :color="['#409eff', '#8cc5ff']" style="width:100px;height:100px;">
					<span style="color:#1289ff;font-size:16px;font-weight:700;">{{ totalTJ.zs_num }}</span>
				</dv-decoration-9>
				<div class="flexCenter mt10" style="width:100%;color:#409eff;font-weight:700;">招生学生总数</div>
			</div>

			<div class="flexCloumn" style="margin-left:80px; align-items:center;">
				<dv-decoration-9 :dur="20" :color="['#409eff', '#8cc5ff']" style="width:100px;height:100px;">
					<span style="color:#1289ff;font-size:16px;font-weight:700;">{{ totalTJ.bys_num }}</span>
				</dv-decoration-9>
				<div class="flexCenter mt10" style="width:100%;color:#409eff;font-weight:700;">毕业学生总数(人)</div>
			</div>
			<div class="flexCloumn" style="margin-left:80px;">
				<dv-decoration-9 :dur="20" :color="['#409eff', '#8cc5ff']" style="width:100px;height:100px;">
					<span style="color:#1289ff;font-size:16px;font-weight:700;">{{ totalTJ.jiuye_num }}</span>
				</dv-decoration-9>
				<div class="flexCenter mt10" style="width:100%;color:#409eff;font-weight:700;">就业学生总数</div>
			</div>
			<div class="flexCloumn" style="margin-left:80px;">
				<dv-decoration-9 :dur="20" :color="['#409eff', '#8cc5ff']" style="width:100px;height:100px;">
					<span style="color:#1289ff;font-size:16px;font-weight:700;">{{ totalTJ.zhijie_num }}</span>
				</dv-decoration-9>
				<div class="flexCenter mt10" style="width:100%;color:#409eff;font-weight:700;">直接就业学生数</div>
			</div>
			<div class="flexCloumn" style="margin-left:80px;">
				<dv-decoration-9 :dur="20" :color="['#409eff', '#8cc5ff']" style="width:100px;height:100px;">
					<span style="color:#1289ff;font-size:16px;font-weight:700;">{{ totalTJ.shengxue_num }}</span>
				</dv-decoration-9>
				<div class="flexCenter mt10" style="width:100%;color:#409eff;font-weight:700;">升学学生数</div>
			</div>
			<div class="flexCloumn" style="margin-left:80px;">
				<dv-decoration-9 :dur="20" :color="['#409eff', '#8cc5ff']" style="width:100px;height:100px;">
					<span style="color:#1289ff;font-size:16px;font-weight:700;">{{ totalTJ.canjun_num }}</span>
				</dv-decoration-9>
				<div class="flexCenter mt10" style="width:100%;color:#409eff;font-weight:700;">参军学生数</div>
			</div>
			<div class="flexCloumn" style="margin-left:80px;">
				<dv-decoration-9 :dur="20" :color="['#409eff', '#8cc5ff']" style="width:100px;height:100px;">
					<span style="color:#1289ff;font-size:16px;font-weight:700;">{{ totalTJ.duikou_num }}</span>
				</dv-decoration-9>
				<div class="flexCenter mt10" style="width:100%;color:#409eff;font-weight:700;">对口就业数</div>
			</div>
			<div class="flexCloumn" style="margin-left:80px;">
				<dv-decoration-9 :dur="20" :color="['#409eff', '#8cc5ff']" style="width:100px;height:100px;">
					<span style="color:#1289ff;font-size:16px;font-weight:700;">{{ totalTJ.daiye_num }}</span>
				</dv-decoration-9>
				<div class="flexCenter mt10" style="width:100%;color:#409eff;font-weight:700;">待业学生数</div>
			</div>

		</div>

		<div class=flexBetween style="width:100%;height:calc(100% - 250px);margin-top:0%;">

			<div class="bottomLeft" style="width:calc(37% - 10px);height:100%;overflow:hidden;">
				<!-- <div class="bottomLeft" style="width:443px;height:362px;"> -->
				<dv-border-box-12 :color="['#409eff', '#8cc5ff']" :dur="15" class="pdng10"
					style="width: 100%;height: 100%;">
					<!-- <div class="flexStart">
                        <div style="color:#5cd9e8;">
                            <i class="el-icon-s-data"></i>
                        </div>
                        <span style="margin-left:10px;">
                            各系部缴费情况
                        </span>
                        <dv-decoration-1 style="width:30%;height:10px;" />
                    </div> -->
					<div id="bottomLeftChart" style="width: 100%; height: calc(100% - 10px);margin-top: 10px;"></div>
				</dv-border-box-12>
			</div>

			<div class="bottomLeft ml10" style="width:63%;height:100%;overflow:hidden;">
				<dv-border-box-12 :color="['#409eff', '#8cc5ff']" :dur="15" class="pdng10"
					style="width: 100%;height: 100%;">
					<!-- <div class="flexStart">
                        <div style="color:#5cd9e8;">
                            <i class="el-icon-s-data"></i>
                        </div>
                        <span class="flexStart" style="margin-left:10px;">
                            <span class="mr10" style="font-weight: 700;">{{ deptName }} </span>
                            各班级缴费情况
                        </span>
                        <dv-decoration-1 style="width:30%;height:10px;" />
                    </div> -->

					<div id="bottomRightChart" style="width: 100%; height: calc(100% - 10px);margin-top: 10px;">

					</div>

				</dv-border-box-12>
			</div>
		</div>
	</div>
</template>
<script>
	import echartMixins from "../../js/resizeMixins";

	export default {
		name: 'schoolStudent',
		components: {},
		data() {
			return {
				chart: null,
				charts: null,
				year: sessionStorage.getItem("year"),
				waterConfig1: {
					data: [66, 45],
					shape: "roundRect",
					formatter: "{value}",
					waveNum: 3,
					waveOpacity: 0.15,
					colors: ['#8cc5ff', '#1289ff']
				},
				waterConfig2: {},
				waterConfig3: {},
				waterConfig4: {},
				searchForm: {
					ktype: 'stu_name',
					status: ""
				}, //查询
				pmid: 1,
				termIdInit: '',
				termList: [],
				deptName: '',
				totalTJ: {},
				deptList: [],
				active: 'dept',
				classList: [],
				curTime: '',
				weeks: ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
				timmer: null
			}
		},
		watch: {

		},
		created() {

		},
		mixins: [echartMixins],

		mounted() {
			let _this = this
			if (localStorage.getItem('year')) {
				this.year = localStorage.getItem('year')
			}
			this.timmer = setInterval(() => {
				_this.updateClock()
			}, 1000);


			this.$nextTick(() => {
				$("#btnxlsbox").width($(window).width() - 500)
			})

			this.getData()




		},
		beforeDestroy() {
			clearInterval(this.timmer);
			this.timmer = null
		},
		destroyed() {
			window.onresize = null;
			this.timmer = null
		},
		methods: {
			getData() {
				let data = {
					xuezhi: "合计"
				}
				this.$http.post("/api/get_jiuye_tongjitable", data).then(res => {

					if (res.data.length > 0) {
						this.totalTJ = res.data[0]
					}

				})

				this.$http.post("/api/get_jiuye_charts").then(res => {
					this.drawDept(res.data.xibuData)
					this.drawClass(res.data.classData)

				})



			},
			updateClock() {

				let _this = this
				let date = new Date(),
					year = date.getFullYear().toString(),
					month = (date.getMonth() + 1).toString().padStart(2, '0'),
					day = date.getDate().toString().padStart(2, '0'),
					hours = date.getHours().toString().padStart(2, '0'),
					minutes = date.getMinutes().toString().padStart(2, '0'),
					seconds = date.getSeconds().toString().padStart(2, '0'),
					week = date.getDay();
				this.curTime = `${year}年${month}月${day}日 ${hours}:${minutes}:${seconds} ${this.weeks[week]}`
			},



			getDeptData() {
				let _this = this
				return new Promise(resolve => {
					_this.$http.post("/api/sch_stu_fee_chart ", {
						term_id: this.searchForm.term_id
					}).then(res => {
						if (res.data && res.data.length > 0) {
							res.data.map(a => {
								a.nums = a.nums ? a.nums : 0
								a.fee_num = a.fee_num ? a.fee_num : 0
								a.fee_rate = a.fee_rate ? a.fee_rate : 0
								if (a.fee_rate) {
									a.fee_rate = `${a.fee_rate * 100}`
								}
							})
							this.deptName = res.data[0].name
							let dataList = res.data
							this.deptList = dataList
							resolve(dataList)
						} else {
							resolve([])
						}
					})

				})
			},
			drawDept(dataList) {
				let _this = this

				this.charts = this.$echarts.init(document.getElementById("bottomLeftChart"));



				let option = this.handelOption1(dataList)
				this.charts.setOption(option);

				if (this.charts) {
					this.charts.off("click")
					this.charts.on("click", (params)=> {
						console.log(params);
						console.log("点击了第",(params.dataIndex+1),"个柱子",params.name)
						
						
						this.$http.post("/api/get_jiuye_charts",{xibu_name:params.name}).then(res => {
							this.drawClass(res.data.classData,params.name)
						
						})
					});
				}







			},
			handelOption1(dataList,title) {
				let dataX = this._lo.map(dataList, 'name')
				// let dataY_total = this._lo.map(dataList, 'nums')
				let dataY_num = this._lo.map(dataList, 'n')
				let dataY_zhijie = this._lo.map(dataList, 'zhijie_num')
				let dataY_shengxue = this._lo.map(dataList, 'shengxue_num')
				let dataY_duikou = this._lo.map(dataList, 'duikou_num')

				// let dataY_rate = this._lo.map(dataList, 'fee_rate')

				let option = {
					// animation: false,
					title: {
						text: (title?title:'') + "系部上报人数统计"
					},
					tooltip: {
						trigger: "axis",
						backgroundColor: "rgba(255,255,255,0.1)",
						axisPointer: {
							type: "shadow",
							label: {
								show: true,
								backgroundColor: "#7B7DDC"
							}
						},
						textStyle: {
							// 设置文字颜色为黑色
							color: '#000'
						},
						formatter: function (params) {
						    //console.log(params)//#F02FC2 #956FD4  rgba(156,107,211,0.8)
							console.log(params);
								console.log(dataY_num[params[0].dataIndex]);
						    //dataX
						    let innerHtml = `<span>上报毕业人数：${dataY_num[params[0].dataIndex] ?? 0}人</span>
							<br /><span>直接就业人数：${dataY_zhijie[params[0].dataIndex] ?? 0}人</span>
							<br /><span>升学人数：${dataY_shengxue[params[0].dataIndex] ?? 0}人</span>
							<br /><span>对口就业人数：${dataY_duikou[params[0].dataIndex] ?? 0}人</span>`
						    

						    return innerHtml
						},
					},
					legend: {
						data: ["上报人数"],
						textStyle: {
							color: "#B4B4B4"
						},
						right: 0
					},
					grid: {
						x: 50,
						y: 55,
						// left: '10%',    // 距离左边的距离
						// right: '10%',   // 距离右边的距离
						// top: '10%',     // 距离顶部的距离
						bottom: '20%', // 距离底部的距离
						// containLabel: true // 包含坐标轴的标签
					},
					xAxis: {
						data: dataX,
						axisLine: {
							lineStyle: {
								color: "#B4B4B4"
							}
						},
						axisTick: {
							show: false,
						},
						axisLabel: {
							show: true,
							interval: 0,
							rotate: 60,
						},
					},
					yAxis: {
						name: '上报人数',
						nameTextStyle: { // 设置标题样式
							color: '#606266',
							fontSize: 12,
							padding: [0, 0, 0, 42]
						},

						min: 0, //最小值

						axisLine: {
							show: true
						},
						axisTick: {
							show: true
						},
						axisLabel: {
							color: '#000'
						}
					},
					series: [

						{
							name: "系部上报人数",
							type: "bar",
							barWidth: 10,
							itemStyle: {
								color: '#33a9ff',
							},
							emphasis: {
								itemStyle: {
									color: '#33a9ff',
								}
							},
							label: {
								show: true,
								position: 'inside',
							},
							data: dataY_num
						},
						




					]
				};
				return option
			},
			handelOption(dataList,title) {
				let dataX = this._lo.map(dataList, 'name')
				// let dataY_total = this._lo.map(dataList, 'nums')
				let dataY_num = this._lo.map(dataList, 'n')
				// let dataY_rate = this._lo.map(dataList, 'fee_rate')
				let dataY_zhijie = this._lo.map(dataList, 'zhijie_num')
				let dataY_shengxue = this._lo.map(dataList, 'shengxue_num')
				let dataY_duikou = this._lo.map(dataList, 'duikou_num')
				let option = {
					// animation: false,
					title: {
						text: (title?title+"/":'')+ "班级上报人数统计"
					},
					tooltip: {
						trigger: "axis",
						backgroundColor: "rgba(255,255,255,0.1)",
						axisPointer: {
							type: "shadow",
							label: {
								show: true,
								backgroundColor: "#7B7DDC"
							}
						},
						textStyle: {
							// 设置文字颜色为黑色
							color: '#000'
						},
            formatter: function (params) {
              //console.log(params)//#F02FC2 #956FD4  rgba(156,107,211,0.8)
              console.log(params);
              console.log(dataY_num[params[0].dataIndex]);
              //dataX
              let innerHtml = `<span>上报毕业人数：${dataY_num[params[0].dataIndex] ?? 0}人</span>
							<br /><span>直接就业人数：${dataY_zhijie[params[0].dataIndex] ?? 0}人</span>
							<br /><span>升学人数：${dataY_shengxue[params[0].dataIndex] ?? 0}人</span>
							<br /><span>对口就业人数：${dataY_duikou[params[0].dataIndex] ?? 0}人</span>`


              return innerHtml
            },
						// formatter: function (params) {
						//     //console.log(params)//#F02FC2 #956FD4  rgba(156,107,211,0.8)
						//     let p0 = undefined //缴费率 p0.name p0.seriesName p0.value
						//     let p1 = undefined //已缴费人数 p1.seriesName p1.value
						//     let p2 = undefined //应缴费人数 p2.seriesName p2.value
						//     let cityname = undefined
						//     let innerHtml = '',
						//         inner0 = '',
						//         inner1 = '',
						//         inner2 = '',
						//         inner = ''
						//     if (params && params.length > 0) {
						//         params.map(a => {
						//             if (a.seriesName == "缴费率") {
						//                 p0 = a
						//                 cityname = a.name
						//             }
						//             if (a.seriesName == "已缴费人数") {
						//                 p1 = a
						//                 cityname = a.name
						//             }
						//             if (a.seriesName == "应缴费人数") {
						//                 p2 = a
						//                 cityname = a.name
						//             }
						//         })
						//     }

						//     //dataX
						//     inner = `
						//     <span>${cityname ? cityname : ''}</span>
						//     <span style="color: #1289ff">${(p0 && p1 && p2) ? (((p1.value > p2.value) || ((p1.value == p2.value))) ? '(全部缴费)' : '') : ''}</span>
						//     <br/>`
						//     // 已缴费人数
						//     inner0 = `
						//     <span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:#3EACE5;"></span>
						//     <span>
						//     ${(p1) ? p1.seriesName : ''}${(p1) ? ':' : ''}

						//     <span>${p1.value}</span>
						//     <span>${(p1 && !p2) ? p1.value : ''}</span>
						//     </span><br/>`
						//     //应缴费人数
						//     inner1 = `
						//      <span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:#956FD4;"></span>
						//      <span>${p2.seriesName}:${p2.value}</span> <br/>`
						//     //缴费率
						//     inner2 = `
						//      <span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:#F02FC2;"></span>
						//     <span>
						//     ${(p0) ? p0.seriesName : ''}${(p0) ? ':' : ''}
						//     <span>${p0.value}%</span>
						//     </span>`

						//     innerHtml = inner + inner0 + inner1 + inner2
						//     return innerHtml
						// },
					},
					legend: {
						data: ["上报人数"],
						textStyle: {
							color: "#B4B4B4"
						},
						right: 0
					},
					grid: {
						x: 50,
						y: 55,
						// left: '10%',    // 距离左边的距离
						// right: '10%',   // 距离右边的距离
						// top: '10%',     // 距离顶部的距离
						bottom: '20%', // 距离底部的距离
						// containLabel: true // 包含坐标轴的标签
					},
					xAxis: {
						data: dataX,
						axisLine: {
							lineStyle: {
								color: "#B4B4B4"
							}
						},
						axisTick: {
							show: false,
						},
						axisLabel: {
							show: true,
							interval: 0,
							rotate: 60,
						},
					},
					yAxis: {
						name: '上报人数',
						nameTextStyle: { // 设置标题样式
							color: '#606266',
							fontSize: 12,
							padding: [0, 0, 0, 42]
						},

						min: 0, //最小值

						axisLine: {
							show: true
						},
						axisTick: {
							show: true
						},
						axisLabel: {
							color: '#000'
						}
					},
					series: [

						{
							name: "班级人数",
							type: "bar",
							barWidth: 10,
							itemStyle: {
								color: '#33a9ff',
							},
							emphasis: {
								itemStyle: {
									color: '#33a9ff',
								}
							},
							label: {
								show: true,
								position: 'inside',
							},
							data: dataY_num
						},
						/*{
							name: "直接就业人数",
							type: "bar",
							barWidth: 10,
							itemStyle: {
								color: '#33a9ff',
							},
							emphasis: {
								itemStyle: {
									color: '#33a9ff',
								}
							},
							label: {
								show: true,
								position: 'inside',
							},
							data: dataY_zhijie
						},
						{
							name: "升学人数",
							type: "bar",
							barWidth: 10,
							itemStyle: {
								color: '#33a9ff',
							},
							emphasis: {
								itemStyle: {
									color: '#33a9ff',
								}
							},
							label: {
								show: true,
								position: 'inside',
							},
							data: dataY_shengxue
						},
						{
							name: "对口就业人数",
							type: "bar",
							barWidth: 10,
							itemStyle: {
								color: '#33a9ff',
							},
							emphasis: {
								itemStyle: {
									color: '#33a9ff',
								}
							},
							label: {
								show: true,
								position: 'inside',
							},
							data: dataY_duikou
						},*/




					]
				};
				return option
			},


			drawClass(dataList,title) {
				this.chart2 = this.$echarts.init(document.getElementById("bottomRightChart"));
				let option = this.handelOption(dataList,title)
				this.chart2.setOption(option);
			},
		}
	}
</script>

<style lang="less" type="text/less">
	.studentContainerTJ {
    width: calc(100vw - 200px);
    height: calc(100vh - 50px);
    padding: 20px;
    overflow: auto;

    div {
        box-sizing: border-box;
    }

    .pdng10 {
        padding: 10px;
    }

    .pdng_rem_dot2 {
        padding: 0.2rem;
    }

    .border {
        border: 0.5px solid #1a5cd7;
        border-radius: 16px;
    }

    .topBar {
        width: 100%;
        height: 9.2%;
        box-sizing: border-box;
    }

    .chartContainer {
        width: 100%;
        height: 86.8%;
        box-sizing: border-box;
        margin-top: 1%;
    }

    // 平行四边形
    .react-left {
        cursor: pointer;
        width: 100%;
        height: 100%;
        line-height: 100%;
        text-align: center;
        transform: skewX(-45deg);
        background-color: #c6e2ff;

        .react-after {
            position: absolute;
            right: -0.3125rem;
            top: 0;
            height: 0.625rem;
            width: 0.625rem;
            transform: skewX(45deg);
        }

        .text {
            display: inline-block;
            transform: skewX(45deg);
            margin-right: 1rem;
        }
    }

    .react-right {
        cursor: pointer;
        width: 100%;
        height: 100%;
        line-height: 100%;
        text-align: center;
        transform: skewX(45deg);
        background-color: #c6e2ff;

        .react-before {
            position: absolute;
            left: -0.3125rem;
            top: 0;
            height: 0.625rem;
            width: 0.625rem;
            transform: skewX(-45deg);
        }

        .text {
            display: inline-block;
            transform: skewX(-45deg);
            margin-left: 1rem;
        }
    }
}
</style>