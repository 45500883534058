<template>
	<div class="w_100 h_100 cbox">
		<div v-if="false" class="headbar">

			<div style="padding-top: 10px" v-if="activeName == 'chart'">
				<div style=" width: 200px;  margin-top:-10px;">
					<div style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;">
						<img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" />
					</div>
					<div style="margin-left: 50px; color:#535353; font-size:20px !important">上报统计图表</div>
				</div>

			</div>


			<div style="float: right">

				<!-- <el-button v-if="shengxue.total > 0 && shengxue.nofile > 0" icon="el-icon-upload" type="danger"
					style="margin-left:10px" size="small" @click="showShengxue">升学人数{{ shengxue.total }}人，录取通知书未上传{{
						shengxue.nofile }}人,点击上传通知书</el-button> -->

				<!-- <el-button v-if="shengxue.total > 0 && shengxue.nofile == 0" icon="el-icon-view" type="success"
					style="margin-left:10px" size="small" @click="showShengxue">录取通知书已全部上传</el-button> -->

				<!-- <el-button icon="el-icon-upload" style="margin-left:10px" size="small"
					@click="dialogShow = true">导入分类表</el-button> -->

				<el-button icon="el-icon-upload" v-if="activeName == 'forth'" style="margin-left:10px" size="small"
					@click="dialogShow3 = true">导入就业名册</el-button>




			</div>
			<div style="padding-top: 14px" v-if="activeName == 'forth'">
				<!-- <div style=" width: 200px;  margin-top:-10px;"> -->
				<!-- <div style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;"> -->
				<!-- <img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" /> -->
				<!-- </div> -->
				<!-- <div style="margin-left: 50px; color:#535353; font-size:20px !important">就业调查</div> -->
				<!-- </div> -->

			</div>
		</div>
		<div class="w_100 h_100 bcontent flexCloumn" style="padding:20px 0;">
			<div class="w_100 flexBetween">
				<el-tabs class="flex_1" v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="毕业生就业名册" name="forth"></el-tab-pane>
					<el-tab-pane label="上报统计" name="chart"></el-tab-pane>
				</el-tabs>
				<el-tabs class="" v-model="activeName" @tab-click="handleClick">
					<el-tab-pane disabled v-if="activeName == 'forth'">
						<div slot="label" class="flexStart">
							<el-button v-if="guidenceUrl" icon="el-icon-question" style="" size="mini"
								@click="openGuidence">使用手册</el-button>
							<el-button icon="el-icon-download" style="" size="mini"
								@click="download_import_module('/xls/毕业生就业名册模板.xls')">就业学生名册表导入模板下载（excel/xls）</el-button>
							<!-- <div class="ml10" style="">
								<a href="/xls/毕业生就业名册模板.xls" target="_blank">就业学生名册表导入模板下载（excel/xls）</a>
							</div> -->
							<el-button v-if="$store.state.sysopened" class="ml10" icon="el-icon-upload" style="" size="mini" type="primary"
								@click="dialogShow3 = true">导入就业名册</el-button>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>


			<mingceChart v-if="activeName == 'chart'"></mingceChart>


			<jymingce class="w_100 flex_1" style="height:calc(100% - 54px)" v-if="activeName == 'forth'"></jymingce>


		</div>



		<el-dialog class="cus_dialog " title="导入毕业生分类表" :visible.sync="dialogShow" :close-on-click-modal="false"
			width="600px" height="30%">
			<div style="line-height: 50px;">
				<a href="/xls/毕业生分类表模板.xlsx" target="_blank">毕业生分类表导入模板下载（excel/xls）</a>
			</div>
			<div style="line-height: 50px; color:orangered">
				请严格按照模板格式填入正确的数值
			</div>

			<div style="text-align:right">
				<el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadTemp"
					:on-success="uploadFiles" :show-file-list="false" name="image">
					<el-button icon="el-icon-upload" type="primary" style="margin-left:100px"
						size="small">选择毕业生分类表文件</el-button>
				</el-upload>
			</div>
		</el-dialog>

		<el-dialog class="cus_dialog " title="就业学生名册表" :visible.sync="dialogShow3" :close-on-click-modal="false"
			width="600px" height="30%">
			<div style="line-height: 50px;">
				<a href="/xls/毕业生就业名册模板.xls" target="_blank">就业学生名册表导入模板下载（excel/xls）</a>
			</div>
			<div style="line-height: 50px; color:orangered">
				请严格按照模板格式填入正确的数值
			</div>

			<div style="text-align:right">
				<el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadTemp"
					:on-success="uploadFiles3" :show-file-list="false" name="image" accept=".xls,.xlsx">
					<el-button icon="el-icon-upload" type="primary" style="margin-left:100px"
						size="small">选择就业学生名册表文件(单个xls)</el-button>
				</el-upload>
			</div>

			<div style="text-align:right;padding-top: 10px;">
				<el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadTemp"
					:on-success="uploadFiles3" :show-file-list="false" name="image" accept=".zip">
					<el-button icon="el-icon-upload" type="primary" style="margin-left:100px"
						size="small">选择就业学生名册表文件(打包上传.zip)</el-button>
				</el-upload>
			</div>


		</el-dialog>


		<el-dialog class="cus_dialog " title="升学学生名单" :visible.sync="dialogSxShow" :close-on-click-modal="false"
			width="90%" height="30%">
			<el-table :data="tableData" row-key="id" border stripe>
				<el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
				<!-- <el-table-column label="学校" prop="platform" min-width="150" align="center"></el-table-column> -->
				<el-table-column label="专业" prop="xibu_name" width="200" align="center"></el-table-column>
				<el-table-column label="所属班级" prop="class_name" width="150" align="center"></el-table-column>
				<el-table-column label="姓名" prop="stu_name" width="100" align="center"></el-table-column>
				<el-table-column label="性别" prop="gender" width="80" align="center"></el-table-column>


				<el-table-column label="年龄" prop="age" width="80" align="center"></el-table-column>


				<el-table-column label="毕业去向" prop="quxiang" width="180" align="center"></el-table-column>


				<el-table-column label="本人电话" prop="lxdh" width="150" align="center"></el-table-column>
				<el-table-column label="录取通知书" prop="bz" min-width="150" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.file">
							<el-image style="width: 100px; height: 100px" :src="scope.row.file" fit="fit"
								:preview-src-list="[scope.row.file]"></el-image>
							<el-button @click="chooseTzs(scope.row)" type="text" size="small"
								icon="el-icon-refresh"></el-button>
						</div>
						<div v-else>

							<el-button @click="chooseTzs(scope.row)" type="text" size="small">上传</el-button>
						</div>


					</template>

				</el-table-column>


			</el-table>


		</el-dialog>

		<div style="width:0px;height:0px;overflow:hidden">
			<el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadOss"
				:on-success="uploadTzs" :show-file-list="false" name="image">
				<el-button id="btntzs" icon="el-icon-upload" type="primary" style="margin-left:100px"
					size="small">录取通知书</el-button>
			</el-upload>
		</div>


	</div>
</template>

<script>

import mingceChart from './com/mingceChart.vue'
import jymingce from './com/sheet_jymingcebzr.vue'
import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'
export default {
	components: {
		mingceChart, jymingce


	},
	data() {
		return {
			dialogShow: false,
			dialogshow1: false,
			dialogShow3: false,
			dialogSxShow: false,
			activeName: "forth",
			teacherInfo: {},
			tjData: null,
			shengxue: {
				total: 0,
				okfile: 0,
				nofile: 0
			},
			tableData: [],
			tempid: 0,
			guidenceUrl: ''
		}
	},
	mounted() {
		this.getGuidenceUrl()
		this.getDataList()
		this.getShengxue()
		this.getteacherInfo()

	},
	methods: {
		getGuidenceUrl() {
			this.$http.post("/api/sch_configs").then(res => {
				for (let k of res.data) {
					if (k.ktype == 'guidenceUrl_All') {
						this.guidenceUrl = k.kvalue
					}
				}
			})
		},
		openGuidence() {
			// window.open(this.guidenceUrl)
			let a = document.createElement("a");
			fetch(this.guidenceUrl)
				.then((res) => res.blob())
				.then((blob) => {
					a.href = URL.createObjectURL(blob);
					a.download = '使用手册';
					a.style.display = "none";
					a.click();
					window.URL.revokeObjectURL(a.href);
				})
		},
		download_import_module(href) {
			let a = document.createElement("a");
			a.href = href;
			a.style.display = "none";
			a.click();
			window.URL.revokeObjectURL(a.href);
		},
		getShengxue() {
			this.$http.post("/api/jymingce_shengxue_tongji").then(res => {
				this.shengxue = res.data
			})
		}, showShengxue() {
			this.$http.post("/api/jymingce_sx_students").then(res => {
				this.tableData = res.data
				this.dialogSxShow = true
			})
		},
		getteacherInfo() {
			this.$http.post("/api/get_teacher_info").then(res => {

				this.teacherInfo = res.data
			})
		},
		handleClick() {
			if (this.activeName == "second") {
				this.$nextTick(e => {
					$("table", "#tableContent").addClass("dttable")
				})
			}
		},
		getDataList() {

			this.$http.post("/api/get_jiuye_tjtable").then(res => {
				if (!res.data) {
					this.$message.error("还没有上传统计表")
				}
				this.tjData = res.data


				this.handleClick()

			})


		},
		handlePageChange(page) {
			this.page.current_page = page;
			this.getDataList();
		},
		viewInfo(e) {
			// e.keyword = this.searchForm.keyword
			// e.xibu_name = this.searchForm.xibu_name
			// e.class_name = this.searchForm.class_name
			this.xunfang = e

			this.dialogShow = true
		},
		uploadFiles(e) {
			if (e.src.indexOf("doc") > 0) {

				this.$http.post("/api/import_jiuyetj_word", {
					url: e.src
				}).then(res => {


					this.$message.success("导入成功")
					if (res.data.msg && res.data.msg.length > 0) {
						let html = ''
						for (let msg of res.data.msg) {
							html += "<div style='font-size:12px'>" + msg.msg + "</div>"
						}
						this.$alert(html, '未导入数据', {
							dangerouslyUseHTMLString: true
						});
					}
					this.dialogshow1 = false
					this.activeName = "second"
					this.getDataList()
					setTimeout(() => {
						this.handleClick()
					}, 500);



				})

			} else {
				this.$http.post("/api/import_jiuyefl_xls2", {
					url: e.src
				}).then(res => {

					this.getDataList()
					this.$message.success("导入成功")
					if (res.data.msg.length > 0) {
						let html = ''
						for (let msg of res.data.msg) {
							html += "<div style='font-size:12px'>" + msg['学生'] + ":" + msg.msg + "</div>"
						}
						this.$alert(html, '未导入数据', {
							dangerouslyUseHTMLString: true
						});
					}
					this.dialogShow = false
					this.activeName = "none"
					this.$nextTick(e => {
						this.activeName = "third"
					})


				})
			}
		},
		uploadFiles3(e) {
			if (e.src.indexOf("xls") > 0) {

				this.$http.post("/api/import_jiuyemingche_xls2024", {
					url: e.src,
          filename: e.fileName,
				}).then(res => {

					if (res.data.errors && res.data.errors.length > 0) {
						let html = ''
						for (let error of res.data.errors) {
							html += "<div style='font-size:12px'>" + error.msg + "</div>"
						}
						this.$alert(html, `未导入数据${res.data.errors.length}条`, {
							dangerouslyUseHTMLString: true,
              showCancelButton: true,
              cancelButtonText:"下载未导入数据",
              distinguishCancelAndClose: true,
              beforeClose: (action, instance, done) => {
                if (action === 'cancel') {
                  this.exportToExcel([{name:"未导入数据",fails: res.data.errors}])
                }
                done()
              }
						});
					} else {
						this.$message.success("导入成功")
					}

					this.dialogShow3 = false
					this.activeName = ""
					this.$nextTick(e => {
						this.activeName = "forth"
					})

				})

			} else if (e.src.indexOf("zip") > 0) {

				this.$http.post("/api/import_jiuyemingche_xls2024zip", {
					url: e.src
				}).then(res => {
          let showCancel = false
          let errors = res.data.errors
          let html = ""
          for (let error of errors) {
            if (error.msg) {
              html += `<div style='font-size:12px'>“${error.name}”文件中，${error.msg}</div>`
            }
            if (error.fails.length) {
              showCancel = true
              html += `<div style='font-size:12px'>“${error.name}”文件中，未导入数据${error.fails.length}条</div>`
            }
          }

          if (html) {
            this.$alert(html, '未导入数据', {
              dangerouslyUseHTMLString: true,
              showCancelButton: showCancel,
              cancelButtonText: "下载未导入数据",
              distinguishCancelAndClose: true,
              beforeClose: (action, instance, done) => {
                if (action === 'cancel') {
                  this.exportToExcel(errors)
                }
                done()
              }
            });
          } else {
            this.$message.success("导入成功")
          }

					/*if (res.data.msg && res.data.msg.length > 0) {
						let html = ''
						for (let msg of res.data.msg) {
							html += "<div style='font-size:12px'>" + msg.msg + "</div>"
						}
						this.$alert(html, '未导入数据', {
							dangerouslyUseHTMLString: true
						});
					} else {
						this.$message.success("导入成功")
					}*/

					this.dialogShow3 = false
					this.activeName = ""
					this.$nextTick(e => {
						this.activeName = "forth"
					})

				})

			} else {
				this.$message.error("请上传Xls格式文件")
			}
		},
		chooseTzs(e) {
			this.tempid = e.id
			document.getElementById("btntzs").click()
		},
		uploadTzs(e) {
			if (e.src) {

				this.$http.post("/api/jymingce_sx_tzs", {
					url: e.src,
					id: this.tempid
				}).then(res => {
					this.getShengxue()
					this.showShengxue()

				})

			}
		},
    exportToExcel(data) {
      let headers = ["行号","姓名","错误描述"
        ]
      let workbook = XLSX.utils.book_new()
      for (let item of data) {
        if (!item.fails.length) {
          continue
        }
        let exportData = [headers]
        for (let error of item.fails) {
          let arr = [error.ID,error['__EMPTY'],error.msg]
          exportData.push(arr)
        }
        let ws = XLSX.utils.aoa_to_sheet(exportData)
        XLSX.utils.book_append_sheet(workbook,ws, item.name)
      }
      let buf = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' })
      const blob = new Blob([buf], {type: "application/octet-stream;charset=utf-8'"})
      FileSaver.saveAs(blob, `未导入数据.xlsx`)
    }

	}
}
</script>
<style scoped></style>
